<template>
    <section class="h-100">
        <div class="row mx-0 px-3 pl-5 f-600 border-bottom py-2 justify-content-between align-items-center text-general">
            <p>Unidades de medida</p>
        </div>
        <div class="p-3 f-15 h-90" style="width: calc(100vw - 595px);">
            <div class="row mx-0 my-3 px-3 text-general">
                Crea las unidades de medida para los productos que vendas
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:12" name="unidad de medida" class="d-flex flex-column">
                            <label class="ml-2 text-general">Unidad de medida</label>
                            <el-input v-model="model.nombre" maxlength="12" size="small" show-word-limit>
                                <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" rules="required|max:5" name="sigla" class="d-flex flex-column">
                            <label class="ml-2 text-general">Siglas</label>
                            <el-input v-model="model.sigla" class="pr-input-32" size="small" style="width: 110px;" maxlength="5" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto pt-1">
                        <el-tooltip class="item" effect="light" content="Crear unidad de medida" placement="bottom">
                            <div class="btn-red mt-3 cr-pointer br-8" @click="insertUnidad(valid)">
                                <i class="icon-plus f-16 text-white" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <p class="mx-3 my-2 mt-4 text-general">Lista de unidades</p>
            <div v-if="unidades.length" class="overflow-auto custom-scroll" style="height:calc(100% - 145px);">
                <ValidationObserver ref="validacion2" v-slot="{ valid }">
                    <div v-for="(unidad, idx) in unidades" :key="idx" class="row mx-0 my-2">
                        <div class="col-auto">
                            <ValidationProvider v-slot="{errors}" :vid="'vp'+idx" rules="required" name="nombre" class="d-flex flex-column">
                                <el-input v-model="unidad.nombre" :disabled="editar!=idx" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto">
                            <ValidationProvider v-slot="{errors}" :vid="'vp2'+idx" rules="required" name="sigla" class="d-flex flex-column">
                                <el-input v-model="unidad.sigla" :disabled="editar!=idx" style="width: 80px;" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto px-2">
                            <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                                <div class="d-middle-center cr-pointer br-10" style="width:44px;height:44px;">
                                    <i class="icon-pencil-outline hover-icons f-20" @click="editar=idx" />
                                </div>
                            </el-tooltip>
                            <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                <div class="bg-light-f5 text-general2 shadow cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="updateUnidad(unidad, valid)">
                                    <i class="icon-ok-circled-outline f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-0">
                            <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                <div class="d-middle-center cr-pointer br-10" :class="editar!=idx?'':'invisible'" style="width:44px;height:44px;" @click="eliminar(unidad.id)">
                                    <i class="icon-trash-empty f-20 hover-inverse" />
                                </div>
                            </el-tooltip>
                        </div>
                        <el-tooltip class="item" effect="light" placement="right">
                            <div slot="content" class="text-center" style="max-width:135px;">
                                Productos que utilizan esta unidad de medida
                            </div>
                            <div class="br-10 text-white px-2 f-17 m-2 mb-auto bg-general3">
                                <i class="icon-package-variant-closed f-18 mr-2" />
                                <span>{{ unidad.productos_count }}</span>
                            </div>
                        </el-tooltip>
                    </div>
                </ValidationObserver>
            </div>
            <sinDatos v-else icon="list" mensaje="No se ha creado ninguna unidad de medida" />
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar unidad de medida" mensaje="¿Desea eliminar la unidad de medida? Esta acción no puede ser revertida." @eliminar="deleteUnidad" />
    </section>
</template>

<script>
import Unidades from "~/services/configurar/productos";

export default {
    data(){
        return {
            model: {
                nombre: '',
                sigla: ''
            },
            editar: -1,
            unidades: [],
            id_unidad: null,
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        async getDatos(){
            try {
                const { data } = await Unidades.getDatosUnidades(0,1)
                this.unidades = data.unidades
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateUnidad(unidad,valid){
            try {
                if(valid){
                    let datos = {
                        id: unidad.id,
                        nombre: unidad.nombre,
                        sigla: unidad.sigla,
                        id_tienda: null,
                    }
                    const {data} = await Unidades.updateUnidad(datos)
                    this.editar=-1
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async insertUnidad(valid){
            try {
                if(valid){
                    let datos = {
                        nombre: this.model.nombre,
                        sigla: this.model.sigla,
                        id_tienda: null,
                    }
                    const {data} = await Unidades.insertUnidad(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                    this.model.nombre = ''
                    this.model.sigla = ''
                }
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async deleteUnidad(){
            try {
                const {data} = await Unidades.deleteUnidad(this.id_unidad)
                this.notificacion(data.message, data.mensaje, data.icono)
                this.getDatos()
                this.id_unidad = null
                this.$refs.modalEliminar.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id){
            this.id_unidad = id
            this.$refs.modalEliminar.toggle()
        }
    }
}
</script>
